
<template>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="ml-auto mb-md-0 mb-2"
          >
            <div class="d-flex align-items-center justify-content-end mt-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  :value="searchQuery"
                  @input="(val) => $emit('update:searchQuery', val)"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </b-input-group>
            </div>
          </b-col>
  
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  export default {
    props: {
      searchQuery: {
        type: String,
        default: null,
      },
    },
  }
  </script>
  